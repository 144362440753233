<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Nama</small>
            <h6>{{state.detail.name}}</h6>
            <small class="text-muted">Code</small>
            <h6>{{state.detail.code}}</h6>
            <small class="text-muted">Tipe Surat</small>
            <h6>{{state.detail.type.text}}</h6>
            <small class="text-muted">Format Surat</small>
            <h6 v-if="!state.detail.file">-</h6>
            <h6 v-else><a class="btn btn-success" :href="state.detail == null ? '#' : state.detail.link_file" target="_blank"><i class="fa fa-download"></i> Format </a></h6>
            <small class="text-muted">Aktif</small>
            <h6>{{state.detail.is_draftable?"Ya":"Tidak"}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
export default {
  components: {
    RotateSquare5
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.clasification;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("clasification/STATE", state);
      this.$store.dispatch(
        "clasification/getClasificationById",
        this.$route.params
      );
    },
    goBack() {
      this.$store.dispatch("clasification/onCancel");
    }
  }
};
</script>
